html,
body {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
:root {
  --logo-sz: 180px;
}

.side-by-side {
  display: flex;
}

.side-by-side > div {
  display: flex;
  align-items: start;
  /* justify-content: center; */
  flex-grow: 1;
  text-align: center;
}

.contact-page .side-by-side > div {
  align-items: center;
}
.contact-page .side-by-side input {
  background-color: white;
}
.contact-page .side-by-side label {
  flex-grow: 1;
}
.side-by-side label {
  /* margin-right: 24px; */
  text-align: center;
  font-size: 1.2rem;
}
.side-by-side .display-data {
  font-size: 1.2rem;
  text-align: left;
  word-break: break-all;
}
.note-text {
  margin-top: 3px;
  font-size: 0.9rem;
  text-align: left;
  color: rgb(177, 177, 177);
}
.fs-11 label {
  font-size: 1.1rem !important;
}

.ck.ck-editor {
  width: 100% !important;
}
.border-custom {
  height: 100%;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  overflow: hidden;
}
.grey-box {
  background-color: #f4f6f8;
  border-color: transparent;
  padding: 1rem;
  width: 100%;
  min-height: 5rem;
}
.Crop-Controls {
  margin: 20px auto 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.img-validation {
  display: flex;
  font-size: 1rem;
  justify-content: center;
  background-color: rgba(255, 207, 207, 0.651);
  margin: 20px 0 10px;
  border-radius: 10px;
  padding: 10px 30px;
}
.showcase__title {
  margin-left: calc(var(--logo-sz) + 30px);
  text-align: left;
}
.hashtag-box {
  white-space: nowrap;
  background-color: #d9d9d9;
  padding: 5px 10px;
  margin: 10px 5px 10px 0px;
  /* margin-right: 5px; */
  border-radius: 10px;
}
.zIndex-10000 {
  z-index: 10000;
}
.big-modal {
  min-width: 80vw;
  max-width: 90vw;
  max-height: 80vh;
}

.hashtags-title {
  background-color: #ddd;
  padding: 5px;
  border-radius: 5px;
  margin-right: 4px;
  font-size: 0.85rem;
  color: black;
  white-space: nowrap;
}
.ck-toolbar__items {
  flex-wrap: wrap !important;
}
.custom-select {
  width: 50%;
  height: 1.43em;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.table-img {
  margin: auto;
  max-width: 100px;
  max-width: 100px;
  padding: 0.2rem;
  border: 1px solid black;
  border-radius: 10px;
  transition: 0.3s ease-in all;
  background-color: white;
}
.table-img img {
  border-radius: 10px;
}
.table-img:hover {
  transform: scale(1.8);
}
.ck.ck-icon.ck-reset_all-excluded.ck-icon_inherit-color.ck-dropdown__arrow {
  z-index: auto;
}

.banner__img {
  max-width: 100%;
  max-height: 400px;
  display: flex;
  justify-content: center;
  background-color: black;
  /* transition: 1s ease-in all; */
  border-radius: 30px 30px 30px 0;
  overflow: hidden;
}
.banner__img img {
  /* border-radius: 30px 30px 30px 0; */
  object-fit: cover;
  transition: 0.2s ease-in all;
  width: 100%;
}
.banner__img img:hover {
  width: 85%;
  max-width: 100%;
}
.banner__img iframe {
  width: 100%;
  max-width: 100%;
  height: 400px;
}

.logo__img {
  height: var(--logo-sz);
  width: var(--logo-sz);
  bottom: calc((-1 * var(--logo-sz)) / 2);
  left: 0;
  z-index: 10;
  position: absolute;
  border-radius: 50%;
  border: 0.4rem solid azure;
  background-color: azure;
  overflow: hidden;
}
.logo__img img {
  object-fit: cover;
  border-radius: 50%;
}
.img__block {
  position: relative;
  /* padding-bottom: calc(var(--logo-sz) / 2); */
}
.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.p-1-5 {
  padding: 1.5rem;
}
.cursor-pointer {
  cursor: pointer;
}
.doc__box {
  height: auto;
  min-height: 100px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  object-fit: cover;
  overflow: auto;
}
.icon__box {
  display: flex;
  padding: 0;
  font-size: 50px;
  flex-grow: 2;
  align-items: center;
}
.title__box {
  flex-grow: 1;
  font-size: 1.04rem;
  background-color: #c4cbff;
  width: 100%;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_vid__box {
  height: 160px;
  width: auto;
  object-fit: contain;
  overflow: hidden;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.playBtn {
  position: absolute;
  width: 60px;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal_view__img {
  object-fit: cover;
  max-width: 39vw;
  /* max-height: 45vh, */
  width: auto;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.product__img {
  height: 350px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 10px;
  overflow: hidden;
}
.product__img img {
  height: 100%;
}
.display-name-value {
  margin-bottom: 1rem;
}
.display-name-value div:nth-child(1) {
  color: rgb(2, 57, 210);
  font-size: 0.8rem;
}
.display-name-value div:nth-child(2) {
  color: black;
  font-size: 1.1rem;
}
.circle-icon {
  border: 1px solid #d1d1d1;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  margin: 1.5rem 10px;
}
.circle-icon svg {
  filter: brightness(0);
}

/* HEART in Product page start */
[id='heart'] {
  position: absolute;
  left: -100vw;
}

[for='heart'] {
  color: #aab8c2;
  cursor: pointer;
  font-size: 2.2em;
  align-self: center;
  transition: color 0.2s ease-in-out;
}

[for='heart']:hover {
  color: grey;
}

[for='heart']::selection {
  color: none;
  background: transparent;
}

[for='heart']::moz-selection {
  color: none;
  background: transparent;
}

[id='heart']:checked + label {
  color: #e2264d;
  will-change: font-size;
  animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

@keyframes heart {
  0%,
  17.5% {
    font-size: 2.2em;
  }
}

/* HEART in Product page end */

/* ALL MEDIA QUERY START */

@media screen and (max-width: 900px) {
  .side-by-side {
    flex-direction: column;
    margin: 0px 0 5px;
  }
  .custom-select {
    width: 100%;
  }
  .fullWidth-responsive {
    width: 100%;
  }
  .contact-page .side-by-side label {
    text-align: left;
  }
  .big-modal {
    min-width: 90vw;
    max-height: 85vh;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --logo-sz: 150px;
  }
  .banner__img iframe {
    width: 100%;
    max-width: 100%;
    height: 300px;
  }
  .product__img {
    height: 220px;
  }
  .doc__box {
    min-height: 150px;
  }
  .modal_view__img {
    max-width: 70vw;
  }
  .showcase__title {
    margin-left: 0;
    margin-top: 70px;
    text-align: center;
  }
  .side-by-side label {
    font-size: 1rem;
  }
}
@media screen and (max-width: 464px) {
  :root {
    --logo-sz: 120px;
  }
}
